import React, { useEffect, useState } from 'react';
import { XAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { COLORS, getDiffClass, formatSecs, roundUpHour, iAxios, timeStats } from '../../lib/utils';
import { ButtonGroup, Spinner } from '../common/Helper';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { useSelector } from 'react-redux';

const ProjectStatus = ({ sprintStatus, prog }) => {
    sprintStatus = sprintStatus.sort((a, b) => a.perc - b.perc);

    return <div className=" w3-responsive">
        <table className="w3-table w3-border-top">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Project</th>
                    <th>Tasks</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {
                    sprintStatus.map((item, idx) => {
                        const diff = prog - item.perc;
                        const progCls = getDiffClass(diff);
                        return <tr key={`row_${idx}`} className={progCls}>
                            <td>{idx + 1}</td>
                            <td>
                                <Link to={`/project/${item.name}`}>{item.name}</Link></td>
                            <td >
                                <span className="cgs-muted w3-right">[ {item.selfClosed} / {item.total - item.open} / {item.total} ]</span>
                                {item.perc.toPrecision(3)}%
                            </td>
                            <td>

                                <span className="cgs-muted w3-right">{(item.timeSpent * 100 / item.timeEstimate).toPrecision(3)}%</span>
                                <span style={{ display: "inline-block" }}>
                                    {formatSecs(roundUpHour(item.timeSpent))}/  {formatSecs(roundUpHour(item.timeEstimate))}
                                </span>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </div>
}

function change(prop) {
    return formatSecs(prop.timeSpent);
}

export const SprintBacklog = (props) => {

    const [mode, setMode] = useState('Count');
    const onSelect = (mode) => {
        setMode(mode);
    }

    const { sprintStatus, completionStatus, prog, ASSIGNMENTS } = props;

    let charts = [];
    if (mode === 'Count') {
        let data = sprintStatus.sort((a, b) => b.total - a.total);
        charts = [<Pie key="outer" isAnimationActive={false} data={data}
            dataKey="total"
            innerRadius="50%"
            outerRadius="80%"
            cy="50%"
            cx="50%"
            fill="#8884d8" label>
            {
                sprintStatus.map((entry, index) => {
                    ASSIGNMENTS[entry.name] = COLORS[index % COLORS.length]
                    return <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />

                })
            }
        </Pie>,
        <Pie key="inner" isAnimationActive={false} data={completionStatus}
            dataKey="value"
            innerRadius="45%"
            outerRadius="51%"
            cy="50%"
            cx="50%"
            fill="#8884d8" >
            {
                completionStatus.map((entry, index) => {
                    return <Cell key={entry.name} fill={entry.name.indexOf('closed') >= 0 ? '#4caf50' : '#f44336'} />
                })
            }
        </Pie>,

        <Tooltip key="tooltip" />]
    } else {
        let data = sprintStatus.sort((a, b) => b.timeSpent - a.timeSpent);
        charts = [

            <Pie
                key="outer"
                isAnimationActive={false} data={data}
                dataKey="timeSpent"
                innerRadius="40%"
                outerRadius="70%"
                cy="50%"
                cx="50%"
                fill="#8884d8" label={change}>
                {
                    sprintStatus.map((entry, index) => {
                        ASSIGNMENTS[entry.name] = COLORS[index % COLORS.length]
                        return <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />

                    })
                }
            </Pie>,
            <Tooltip formatter={formatSecs} key="tooltip" />]
    }
    return <div className=" w3-white">
        <div className="w3-border-bottom ">
            <h4 className="w3-center w3-padding-small">Sprint Backlog <i
                style={{ fontSize: "14px", color: "#777" }} >Time elapsed {prog}%</i></h4>
        </div>
        <div className="w3-margin-top">
            <ButtonGroup buttons={["Count", "Time Spent"]}
                selected={mode} onSelect={onSelect} />
        </div>
        <div style={{ maxWidth: "480px", margin: 'auto' }}>
            <ResponsiveContainer width="100%" aspect={1}>
                <PieChart margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                    {charts}
                </PieChart>
            </ResponsiveContainer>
        </div>
        <ProjectStatus sprintStatus={sprintStatus} prog={prog} />
    </div>
}

export const TodaysProgress = () => {

    const [data, setData] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [datesToShow, setDatesToShow] = useState([]);
    const userFilter = useSelector(st => st.tickets.userFilter);

    const makeDateVisible = (dt) => {
        if (datesToShow.includes(dt)) {
            setDatesToShow(datesToShow.filter(item => item != dt))
        } else {

            setDatesToShow([...datesToShow, dt]);
        }
    }
    
    useEffect(() => {
        iAxios.get('/api/v1/tickets/important').then(resp => {

            setData(resp.data.list);
            let closest = 1000000;
            let selected = null;
            const dList = resp.data.list.reduce((agg, item) => {
                const dt = moment(item.dueDate).format('YYYY-MM-DD');
                if (!agg.includes(dt)) {
                    agg.push(dt);
                    const diff = Math.abs(moment().startOf('day').diff(dt, 'mins'));
                    if (diff < closest) {
                        selected = dt;
                        closest = diff;
                    }
                }
                item.dueDate = dt;
                return agg;
            }, []);
            setDateList(dList);
            if (selected) {
                setDatesToShow([selected])
            }
        })

    }, [])
 
    return <div className=' w3-white'>
        <div className=" ">
            <h4 className="w3-center w3-padding-small">Day Focus</h4>
        </div>
        <div className='w3-white'>
            {!dateList && <Spinner/>}
            {dateList.length == 0 && <div className='w3-padding w3-center'>Please add tasks and chechins to see data under this section</div>}
            {dateList.map(dt => {
                const showList = datesToShow.includes(dt);
                const filtered = data.filter(issue => {
                    if (!userFilter.some(u => u.username === issue.assignee.username)) {
                        return false;
                    }
                    return issue.dueDate === dt;
                } );

                if (filtered.length === 0){
                    return null;
                }
                return <div className='w3-light-grey w3-row ' key={`item-${dt}`}>
                    <div className='w3-left w3-padding-small'>{dt}</div>
                    <div className='w3-right'> {filtered.length}<button className="w3-margin-left w3-button w3-padding-small "
                        style={{ minWidth: '28px' }}
                        onClick={e => makeDateVisible(dt)}>
                        {showList ? <i className='ion-ios-arrow-down' /> : <i className='ion-ios-arrow-right' />}</button></div>
                    <div className='w3-row w3-border-top'>
                        {showList && filtered.map(issue =>
                            <div key={`item-${issue.id}`} className='w3-padding-small w3-white w3-border-bottom w3-row '>
                                <a href={issue.url} target="_blank" rel="noreferrer" style={{
                                    textDecoration: 'none',
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    marginBottom:'8px'
                                }} >{issue.title}</a>
                                <div className="w3-right-align ">
                                    <span className="cgs-small-tag w3-pale-green w3-left">
                                        {timeStats(issue)}
                                    </span>
                                    <span className='w3-text-grey w3-right'>{issue.assignee.name}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            )}
        </div>
    </div>
}