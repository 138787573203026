/*  eslint indent : "off" , react/jsx-indent: "off"
*/
import React, { useState } from 'react';
import * as moment from 'moment';
import {
    filterIssues,
    timeStats,
    formatDay,
    getIssueProject,
} from '../../lib/utils';
import { useConf, useTicketFetcher } from '../../lib/hooks';
import { Spinner } from '../common/Helper';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const IssueCard = ({
    issue
}) => {
    const dispatch = useDispatch();
    let cls = issue.state === 'closed' ? 'cgs-border-top-green' : '';
    if (issue.timeEstimate === null) {
        cls = 'cgs-border-top-red';
    }

    const handleClick = (e) => {
        if (e.target.href.indexOf('internal://') == -1) {
            return;
        }
        e.preventDefault();
        dispatch({
            type: 'show-modal',
            name: 'issueModal',
            data: issue.ticketId
        })
    }
    return (
        <div className={`${cls} w3-white  w3-margin-bottom  w3-padding-small`}>
            <div>
                <a style={{ textDecoration: "none" }} href={issue.url} onClick={handleClick}
                    rel="noreferrer" target="_blank">{issue.title}</a></div>
             
                <div>
                    {issue.labels.map(l => <span key={l} className="cgs-small-tag w3-yellow">{l}</span>)}
                    <span className={classNames("cgs-small-tag w3-small ", {
                        'w3-red': issue.timeSpent > issue.timeEstimate,
                        'w3-green': !(issue.timeSpent > issue.timeEstimate)
                    })} title="Spent / Estimate">
                        {timeStats(issue)}
                    </span>
                    <span className='cgs-small-tag w3-purple w3-small'>
                        {moment(issue.dueDate).format('MMM DD')}
                    </span>
                    <div className=" w3-text-grey w3-right-align w3-margin-top ">{issue.assignee && issue.assignee.name}</div>
                </div>
 
        </div>
    )
}

const IssueBoard = (props) => {

    const [swimMode, setSwimMode] = useState('project');
    const [showCards, setShowCards] = useState(true);

    const { workflow: filters } = useConf();
    const { ticketListFiltered } = useTicketFetcher(props.filter)
 

    const modeChange = (e) => {
        setSwimMode(e.target.value);
    }

    const changeCardView = (e) => {
        setShowCards(e.target.checked);
    }


    if (!ticketListFiltered) {
        return <Spinner />
    }

    let swimLanes = { 'Team': ticketListFiltered };

    if (swimMode && swimMode !== 'team' && ticketListFiltered) {
        swimLanes = ticketListFiltered.reduce((acc, issue) => {

            let name = 'UnAssigned';
            if (swimMode === 'project') {
                name = issue.project.key;
            } else if (swimMode === 'user') {
                if (issue.assignee) {
                    name = issue.assignee.name;
                }
            }
            if (!acc[name]) {
                acc[name] = [];
            }
            acc[name].push(issue);
            return acc;
        }, {});
    }

    const headers = [];
    let rows = [];
    let completedCount = 0;
    Object.keys(swimLanes).forEach(name => {
        const cols = []
        let rowTotal = 0;
        let rowSpentTotal = 0;
        const list = filters.map((title, idx) => {
            const list = filterIssues(swimLanes[name], title.toLowerCase());

            let label = <span className="w3-small">{title}</span>;
            const totalTm = list.reduce((acc, i) => acc + i.timeEstimate, 0);
            const totalSpend = list.reduce((acc, i) => acc + i.timeSpent, 0);
            if (title.toLowerCase() === 'closed') {
                completedCount = list.length;
            }

            rowTotal += totalTm;
            rowSpentTotal += totalSpend;
            cols.push(<td key={`col_${idx}`} style={{ padding: "2px 8px" }} className="cgs-kanban-col">{label}
                <span className="cgs-small-tag w3-right w3-blue">{list.length}</span>
                <span className="cgs-small-tag w3-right w3-blue-grey">{formatDay(totalTm)}</span>
            </td>);
            return <td key={`col_${idx}`} className="cgs-kanban-col">
                {list.map(i => <IssueCard key={`card_${i.id}`} issue={i} />)}
            </td>
        });

        let url = '';
        const nList = []
        nList.push(<tr className={completedCount === swimLanes[name].length ? 'w3-green' : "w3-theme-l4 w3-border-bottom"} key={name}>
            <td style={{ padding: "4px 8px" }} colSpan={filters.length}>
                <a className="" style={{ textDecoration: 'none', textTransform: 'capitalize' }} rel="noreferrer"
                    target="_blank" href={url}>{name} </a>
                {/* <span className="cgs-small-tag w3-right w3-white">Velocity: {formatDay(rowSpentTotal / completedCount)}</span> */}
                <span className="cgs-small-tag w3-right w3-white w3-small">Spent: {formatDay(rowSpentTotal)} / E: {formatDay(rowTotal)}</span>

                <span className="cgs-small-tag w3-right w3-white w3-small">Tasks: {completedCount}/{swimLanes[name].length}</span>
            </td>
        </tr>);
        nList.push(<tr className="w3-light-grey" key={name + "_times"}>{cols}</tr>);
        if (showCards) {
            nList.push(<tr key={name + '_issues'}>{list}</tr>);
        }
        if (completedCount === swimLanes[name].length) {
            rows = rows.concat(nList);
        } else {
            rows = nList.concat(rows);
        }
    });


    return <div className="w3-responsive">
        <div>
            <div className="w3-padding-small w3-col m4">
                <select className="w3-select" onChange={modeChange}>
                    <option value="project">Project </option>
                    <option value="user">User</option>
                    <option value='team'>Team</option>
                </select>
            </div>
            <div className="w3-col m4 w3-right-align w3-right w3-padding">
                <label className='w3-margin-right w3-margin-top' style={{ lineHeight: "32px" }}>
                    <input className="w3-checkbox" checked={showCards}
                        onChange={changeCardView} type="checkbox"></input> Show Ticket Cards
                </label>
            </div>
        </div>
        <table className="w3-table " style={{ minWidth: '1024px' }}>
            <thead>
                <tr>{headers}</tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>

}


export default IssueBoard;