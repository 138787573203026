import React, { Component, useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Spinner, TabFilter, TRWrapper } from '../common/Helper';
import { formatSecs, paginatedFetch } from '../../lib/utils'
import BurnDown from '../perf/BurnDown';
import IssueGroup from '../perf/IssueGroup';
import IssuesTable from '../widgets/IssuesTable';
import IssueBoard from '../pages/IssueBoard';
import { useTicketFetcher } from '../../lib/hooks';

const TagCloud = ({ tags, tagStatus, onTagClick }) => {
    let list = [];
    for (var name in tags) {
        let cls = 'w3-blue-grey';
        if (tagStatus[name]) {
            cls = 'w3-green';
        }
        list.push(<button id={name} key={name} title={name}
            onClick={e => onTagClick(e.target.title)}
            style={{ margin: "2px" }} className={`${cls} w3-button w3-padding-small w3-round w3-small  w3-tag `}> {name}
            <span className="w3-deep-orange w3-tag w3-round" style={{ marginLeft: "4px" }}> {tags[name].tasks}</span>
            <span className="w3-blue w3-tag w3-round" style={{ marginLeft: "4px" }}> {formatSecs(tags[name].spent)}</span>
        </button>)
    }
    list = list.sort((a, b) => a.key.localeCompare(b.key));
    return list;
}

const TimePersonTable = (props) => {
    const { total, people, error, message } = props;
    let content = <Spinner />

    const list = [];
    for (var name in people) {
        list.push(<tr key={name}>
            <td>{name}</td>
            <td>{formatSecs(people[name].totalSpent)} / {formatSecs(people[name].totalEstimate)}</td>
        </tr>);
    }

    if (error) {
        list.push(<TRWrapper key="error" colSpan="2">
            <div className="w3-red w3-padding w3-center">
                <h6>{error}</h6>
            </div>
        </TRWrapper>)
    } else if (message) {
        list.push(<TRWrapper key="message" colSpan="2">
            <div className="w3-padding w3-center">
                <h6>{message}</h6>
            </div>
        </TRWrapper>)
    } else if (list.length == 0) {
        list.push(<TRWrapper key="loading" colSpan="2"><Spinner /></TRWrapper>);
    }

    return <table className="w3-table  w3-table-all">
        <thead>
            <tr>
                <th>Person</th>
                <th>Time
                    <span className="cgs-muted">{formatSecs(total)}</span>

                </th>
            </tr>
        </thead>
        <tbody>
            {list}
        </tbody>
    </table>

}


const Project = (props) => {

    const [sp, setSp] = useSearchParams();

    const { project } = useParams();

    let message = null;
    let error = null;

    // const [filter, setFilter] = useState('');
    const [tagStatus, setTagStatus] = useState({});
    const [issues, setIssues] = useState(null);
    const [total, setTotal] = useState(0);
    const [tags, setTags] = useState([]);
    const [people, setPeople] = useState({});

    const { ticketListFiltered } = useTicketFetcher({
        project, 
    });

    const tab = sp.get('tab') || 'list';
    useEffect(() => {
        let newList = ticketListFiltered;
        if (!newList) {
            return;
        }
        newList = newList.filter(item => item.project.name === project);
        const tList = newList.reduce((acc, item) => {
            item.labels.forEach(label => {
                if (!acc[label]) {
                    acc[label] = {
                        tasks: 0,
                        spent: 0
                    }
                }
                acc[label].tasks++;
                acc[label].spent += item.timeSpent;
            });
            return acc;
        }, {});

        setTags(tList);
        console.log(tagStatus)
        if (Object.keys(tagStatus).length != 0) {
            newList = newList.filter(item => item.labels.some(s => tagStatus[s]))
        }

        // if (emailFilter != '') {
        //     if (filter.indexOf("!") == 0) {
        //         filter = filter.replace("!", "");
        //         newList = newList.filter(item => {
        //             return item.title.toLowerCase().indexOf(filter) < 0 &&
        //                 item.assignee.name.toLowerCase().indexOf(filter) < 0 &&
        //                 item.labels.join().toLowerCase().indexOf(filter) < 0
        //         });
        //     } else {
        //         newList = newList.filter(item => {
        //             return item.title.toLowerCase().indexOf(filter) >= 0 ||
        //                 item.assignee.name.toLowerCase().indexOf(filter) >= 0 ||
        //                 item.labels.join().toLowerCase().indexOf(filter) >= 0;
        //         });
        //     }
        // }

        newList = newList.sort((a, b) => {
            const abase = a.state === 'closed' ? 1000 * 86400 : 0;
            const bbase = b.state === 'closed' ? 1000 * 86400 : 0;
            return (abase) - (bbase);
        });


        const t = newList.reduce((acc, item) => acc + parseInt(item.timeSpent), 0);
        setTotal(t);
        const map = newList.reduce((acc, item) => {
            if (!item.assignee) {
                return acc;
            }
            if (!acc[item.assignee.name]) {
                acc[item.assignee.name] = new IssueGroup(item.assignee.username, item.assignee.name)
            }
            acc[item.assignee.name].addIssue(item);
            return acc
        }, {});

        setPeople(map);
        setIssues(newList)

    }, [ticketListFiltered, tagStatus]);


    const onTagClick = (name) => {
        console.log(name)
        if (tagStatus[name]) {
            delete tagStatus[name];
        } else {
            tagStatus[name] = true;
        }
        setTagStatus(Object.assign({}, tagStatus))
    }

    const onEmailChange = (e) => {

    }

    let burnDown = null;
    if (issues) {
        burnDown = <BurnDown issueList={issues} />
    }

    const tabs = ["List", "Board"]

    return <div >
        <h2>{`Project - ${project}`} </h2>

        <div className='w3-row'>
            <div className="w3-col m7 s12 w3-padding-small">
                {burnDown}
            </div>
            <div className="w3-col m5 s12 w3-padding-small">
                {<TimePersonTable error={error} message={message}
                    people={people} total={total} />}
                <div className="w3-margin-top">
                    <TagCloud tags={tags} tagStatus={tagStatus} onTagClick={onTagClick} />
                </div>
            </div>
        </div>
        <div className="w3-padding-small">
            Issues {issues?.length}
            <TabFilter name="tab" filters={tabs} />
            {
                tab !== 'list' ? <IssueBoard 
                    tagStatus={tagStatus}
                    filter={{ project }}
                /> : <IssuesTable tagStatus={tagStatus}
                    error={error} issues={issues} />
            }
        </div>
    </div>

}
export default Project;