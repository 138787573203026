import { useEffect, useState } from "react"
import { iAxios } from "../../../lib/utils"
import { useParams } from "react-router"
import { Users } from "./Users"
import { useDispatch } from "react-redux"
import { alertMessage } from "../../../lib/actions"
import { Integrations } from "./Integrations"
import { Spinner, TabFilter } from "../../common/Helper"


const UpdateRow = ({ item }) => {
    const [name, setName] = useState(item.name);
    const [inFligt, setInFlight] = useState(false);
    const [message,setMessage] = useState(null);

    const handleUpdate = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }
        console.log({ data })
        setInFlight(true)
        iAxios.post('/api/v1/admin/project-update', data).then(resp => {
            console.log('resp', resp.data);
            setInFlight(false);
            setMessage(`Updated ${resp.data.data.result} issues`);
        }).catch(ex => {
            alert(ex.message);
        })
    }

    return <form className="w3-row" onSubmit={handleUpdate}>
        <input type="hidden" value={item.refId} name="projectId" />
        <div className="w3-col m8">
            <input name="projectName" className="w3-input w3-border w3-left" value={name}
                onChange={e => setName(e.target.value)}
                placeholder={item.refId} />
        </div>
        <div className="w3-col m4 w3-left-align w3-padding-small">
            {item.name !== name && <button disabled={inFligt} className="w3-button  w3-blue w3-round w3-padding-small">Update</button>}
        <div className="w3-tiny">{message}</div>
        </div>

    </form>
}
const Projects = () => {

    const [projects, setProjects] = useState(null);
    useEffect(() => {
        iAxios.get('/api/v1/admin/project-list').then(resp => {
            const list = resp.data.data;
            list.sort((a, b) => a.refId.localeCompare(b.refId));
            setProjects(list);
        });
    }, [1])


    return <div>
        {projects == null && <Spinner />}
        <table className="w3-table w3-table-all">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {projects?.map((item, idx) => {
                    return <tr className="w3-display-container">
                        <td>{idx + 1}</td>
                        <td title={item.id}>{item.refId}
                            <div className="w3-tiny w3-text-grey">{item.id}</div>
                        </td>
                        <td style={{ width: '50%' }}>
                            <UpdateRow item={item} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

    </div>

}

const Preferences = (props) => {
}
const Settings = (props) => {
    const { tab = 'users' } = useParams();
    return <>
        <TabFilter mode="path" basePath="/settings"
            filters={['Users', 'Integrations']} ></TabFilter>
        {tab === 'users' && <Users />}
        {tab === 'integrations' && <Integrations />}
        {tab === 'projects' && <Projects />}
    </>
}

export default Settings;