import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import moment, { parseTwoDigitYear } from 'moment';
import { iAxios } from '../../lib/utils';
import { Spinner } from '../common/Helper';
import { Month, LinearMonth } from '../common/Calendar';
import { useSelector } from 'react-redux';

const Leaves = (props) => {

    const userFilter = useSelector(st => st.tickets.userFilter);

    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [responseList, setResponse] = useState(null);
    const [catList, setCatList] = useState([]);
    const [months, setMonths] = useState([]);
    const [people, setPeople] = useState([]);
    const [filteredItems, setFiltered] = useState(null)
    const dbSetSearch = debounce(setSearch, 200);

    useEffect(() => {
        setResponse([]);
        iAxios.get(`/api/v1/utils/leaves`).then(resp => {
            const data = resp.data.data;
            const list = data.sort((a, b) => {
                return moment(a.dueDate).toDate().getTime() - moment(b.dueDate).toDate().getTime();
            });
            list.reverse();
            const cList = data.reduce((agg, item) => {
                if (!agg.includes(item.category)) {
                    agg.push(item.category); 
                }
                return agg;
            }, [])
            cList.sort();
            setCatList(cList);
            setResponse(list);
        }).catch(ex => {
            console.error(ex);
        });
    }, [1])


    useEffect(() => {


        if (!responseList) {
            return;
        }
        const userMap = userFilter.reduce((agg, item) => {
            agg[item.email] = true;
            return agg;
        }, {})

        const filtered = responseList.filter(item => {

            if (search === '' && !userMap[item.assigneeUser?.email]) {
                return false;
            }

            if (category !== 'all' && category !== item.category) {
                return false;
            }

            if (search !== '') {
                const str = search.toLowerCase();
                if (str === 'none') {
                    console.log(item.assigneeUser);
                    return item.assigneeUser == null;
                }

                return item.assigneeUser?.email.indexOf(str) >= 0 ||
                    item.url.indexOf(search) >= 0 ||
                    item.title.toLowerCase().indexOf(str) >= 0
            }
            return true;
        });
        const months = filtered.reduce((agg, item) => {
            let duration = item.timeEstimate / (60 * 60);
            let date = moment(item.dueDate);

            while (duration > 0) {
                const monYr = date.startOf('mon');
                const mon = monYr.format('YYYY-MM-01');
                const day = date.format('ddd');
                if (day === 'Sun' || day === 'Sat') {
                    date.add(1, 'day');
                    continue;
                }

                if (!agg[mon]) {
                    agg[mon] = []
                }
                agg[mon].push({
                    id: item.id,
                    duration: item.timeEstimate,
                    date: date.format('YYYY-MM-DD'),
                    url:item.url,
                    title: item.assigneeUser?.email 
                });
                duration -= 8;
                date.add(1, 'day');
            }
            return agg;
        }, {})

        const people = filtered.reduce((agg, item) => {
            if (!item.assigneeUser) {
                return agg;
            }
            if (!agg[item.assigneeUser.name]) {
                agg[item.assigneeUser.name] = 0
            }
            agg[item.assigneeUser.name] += Math.floor(item.timeEstimate / (3600 * 8))
            return agg;
        }, {})
        setPeople(people);
        setMonths(months);
        setFiltered(filtered);
    }, [responseList, search, category, userFilter])

    return <div >

        <form className="w3-row-padding w3-margin"   >
            <div className="w3-col m9">
                <input className="w3-input" onChange={e => dbSetSearch(e.target.value)} />
            </div>
            <div className="w3-col m3 ">
                <select className="w3-input" onChange={e => setCategory(e.target.value)}>
                    <option value="all">All</option>
                    {catList.map(item => (<option>{item}</option>))}
                </select>
            </div> 
        </form>
        <div className="w3-padding-small scrollable  " >
            <LinearMonth months={months} />
        </div>
        <div style={{ display: 'none', flexWrap: 'wrap' }}>
            {Object.keys(months).map(mon =>
                <div key={mon} className="w3-padding-small m3 s6" style={{ display: 'flex' }}>
                    <Month mon={mon} data={months[mon]} />
                </div>
            )}
        </div>
        <div className="w3-row-padding  w3-margin-top">
            <div className="w3-col m8 l9">
                <table className="w3-table-all ">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th style={{ minWidth: '100px' }}>Date</th>
                            <th>User</th>
                            <th>Days</th>
                            <th>Approver</th>
                            <th>Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems && filteredItems.map((item, idx) => {
                            return <tr>
                                <td>{idx + 1}</td>
                                <td style={{ textAlign: "right" }}>{moment(item.dueDate).format('MMM-DD')}</td>
                                <td>{item.assigneeUser?.email}</td>
                                <td>{item.timeEstimate ? item.timeEstimate / (8 * 3600) : 'time not marked'}</td>
                                <td>{item.approver ? item.approver?.email : 'Not Approved'}</td>
                                <td><a target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }} href={item.url}>
                                    {item.title}
                                    {item.dueDate == null &&
                                        <span title="Date not marked" className="ion-alert-circled w3-margin-left w3-text-red ion"></span>}
                                </a></td>
                            </tr>
                        })}</tbody>
                </table>
                {filteredItems === null && <div className="w3-center w3-padding-64">
                    <Spinner />
                </div>}
            </div>
            <div className="w3-col m4 l3">
                <table className="w3-table w3-table-all">
                    <thead>
                        <tr>
                            <th> #</th>
                            <th>User</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(people).sort().map((name, idx) => {
                            return <tr>
                                <td>{idx + 1}</td>
                                <td>{name}</td>
                                <td>{people[name]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default Leaves;