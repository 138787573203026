import { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { calcSprintStatus, hoursProgressed, iAxios, workingDays } from "./utils";
import { useSearchParams } from "react-router-dom";
import IssueGroup from "../components/perf/IssueGroup";
import { actionFetchIssues } from "./actions";


export function useMilestone() {
    const milestoneList = useSelector(st => st.milestoneList);
    const [milestone, setMilestone] = useState(null);
    const [sp] = useSearchParams();

    useEffect(() => {

        if (!Array.isArray(milestoneList)) {
            return;
        }

        let start = sp.get('start');
        let end = sp.get('end');

        let ms = milestoneList[0];

        if (!start || !end) {
            const tm = moment();
            ms = milestoneList.find(m => {
                const f = tm.isAfter(moment(m.start));
                const s = tm.isBefore(moment(m.end).add(1, 'day'));
                return f && s;
            });
        } else {
            ms = milestoneList.find(item => item.start === start && item.end === end);
        }
        if (!ms && start && end) {
            ms = {
                title: 'Custom',
                start,
                end
            }
        } else if (!ms) {
            ms = {
                title: 'Custom',
                start: moment().subtract(1, 'month').startOf('week').format('YYYY-MM-DD'),
                end: moment().add(1, 'month').endOf('week').format('YYYY-MM-DD'),
            };
        }
        setMilestone(ms);

    }, [milestoneList, sp])
    return milestone;
}


function processTeam(userList, email, path = '') {
    let list = userList.filter(u => u.teamLead === email);
    let user = userList.find(u => u.email === email);
    let result = [];
    if (user) {
        user.path = path;
        result.push(user)
    }
    for (const u of list) {
        const prev = processTeam(userList, u.email, `${path}#${u.username}`)
        result = [...result, ...prev]
    }
    return result
}

export function useTeamList() {
    const milestone = useMilestone();
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);
    const { userInfo, userFilter } = useSelector(st => ({
        userInfo: st.userInfo,
        userFilter: st.tickets.userFilter
    }));

    useEffect(() => {
        if (!milestone) {
            return;
        }
        iAxios.get('/api/v1/users', {
            params: {
                start: milestone.start,
                end: milestone.end
            }
        }).then(res => {
            const users = processTeam(res.data, null, '');
            setUserList(users);
            if (userFilter.length === 0) {
                dispatch({
                    type: 'filter_users',
                    users: users.filter(item => item.path.indexOf(`#${userInfo.username}`) >= 0),
                })
            }
        });
    }, [milestone, userInfo?.email])

    return userList;

}

export function useTicketStats(issueList) {

    const milestone = useMilestone();
    const [data, setData] = useState({
        prog: 0,
        provVal: 0,
        sprintStatus: [],
        tmTotal: 0,
        completionStatus: []
    });

    useEffect(() => {
        if (!Array.isArray(issueList) || issueList.length === 0) {
            return;
        }

        if (!milestone) {
            return;
        }

        const sprintStatus = calcSprintStatus(issueList);
        const completionStatus = sprintStatus.reduce((acc, item) => {
            acc.push({ name: "closed", value: item.total - item.open });
            acc.push({ name: "open", value: item.open });
            return acc;
        }, []);

        const hoursProgress = hoursProgressed(milestone.start, milestone.end);
        const days = workingDays(milestone.start, milestone.end);
        const tmTotal = days * 8 * 3600;

        setData({
            prog: Math.min(100, (hoursProgress * 100 / (days * 8)).toPrecision(4)),
            progVal: Math.min(hoursProgress * 3600, tmTotal),
            tmTotal: tmTotal,
            sprintStatus,
            completionStatus
        })
    }, [issueList, milestone])

    return data;
}

const groupTickets = (list, mode) => {

    if (!list) {
        return null;
    }
    const map = list.reduce((acc, item) => {
        let uqName = 'unAssigned';
        let name = 'Un Assigned';
        if (mode === 'member') {
            if (item.assignee) {
                uqName = item.assignee.username;
                name = item.assignee.name;
            }
        } else if (mode === 'project') {
            uqName = item.project.name;
            name = item.project.name;

        } else if (mode === 'client') {
            uqName = item.project.client;
            name = item.project.client;
        }
        if (!acc[uqName]) {
            acc[uqName] = new IssueGroup(uqName, name, mode);
        }
        acc[uqName].addIssue(item);
        return acc;
    }, {});
    return Object.values(map).sort((a, b) => a.totalEstimate - b.totalEstimate);

}


export const useTicketFetcher = (filterToApply) => {

    const milestone = useMilestone();
    const dispatch = useDispatch();
    const [result, setResult] = useState([])
    const { userFilter, ticketList, ticketListFiltered, fetchFilter } = useSelector(st => st.tickets)
    const [sp] = useSearchParams()
 

    let filter = sp.get('filter');
    if (!filter) {
        filter = 'all'
    }
    useEffect(() => {
        if (!milestone) {
            return;
        }
        if (milestone.start === fetchFilter.start || milestone.end === fetchFilter.end) {
            return;
        }
        dispatch(actionFetchIssues(milestone));
    }, [milestone, fetchFilter, dispatch])

    useEffect(() => {
        if(!ticketListFiltered){
            return;
        }
        let res = ticketListFiltered;
        console.log(ticketListFiltered.length,filterToApply)
        if (res && filterToApply?.project) {
            console.log(res[0]);
            res = res.filter(ticket => ticket.project.name === filterToApply.project )
        }
        setResult(res);
    }, [JSON.stringify(filterToApply), ticketListFiltered])


    return {
        ticketList,
        filter,
        ticketListFiltered: result,
        userFilter,
    }
}

export const useTicketGrouper = (options = []) => {

    const [groupedList, setGroupedList] = useState(null);
    const { ticketList, ticketListFiltered, userFilter, filter } = useTicketFetcher()

    useEffect(() => {
        if (!ticketListFiltered) {
            setGroupedList(null);
            return;
        }
        const list = groupTickets(ticketListFiltered, 'member')
        setGroupedList(list);
    }, [ticketListFiltered])
    return {
        ticketList,
        ticketListFiltered,
        groupedList,
        userFilter,
        filter
    }
}

export const useUserTicketGrouper = (username) => {
    const [groupedList, setGroupedList] = useState(null);
    const { ticketList, ticketListFiltered, userFilter, filter } = useTicketFetcher()

    useEffect(() => {
        if (!ticketList) {
            setGroupedList(null);
            return;
        }
        const filtered = ticketList.filter(t => t.assignee.username === username);
        const list = groupTickets(filtered, 'project')
        setGroupedList(list);
    }, [ticketList, username])

    return {
        ticketList,
        ticketListFiltered,
        groupedList,
        userFilter,
        filter
    }

}

export const useConf = () => {

    const [appConf, setAppConf] = useState(null);
    const [workflow, setWorkflow] = useState([])
    const [loginButtons, setLoginButtons] = useState([{
        name: 'Login With Google',
        value: 'google'
    }])

    useEffect(() => {
        iAxios.get('/api/v1/auth/app-conf').then(resp => {
            const buttons = resp.data.data?.sections?.filter(item => item.module === 'login-buttons' && item.enabled === 'TRUE')
            setAppConf(resp.data.data);
            setLoginButtons(buttons)

            const wf = resp.data.data?.sections?.filter(item => item.module === 'workflow' && item.enabled === 'TRUE')
            setWorkflow([...wf.map(item => item.name)]);
        })
    }, [1])
    return { appConf, loginButtons, workflow };

}
