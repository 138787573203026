import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatSecs, workingDays, roundUpHour, filterIssues, getDiffClass } from '../../lib/utils.js'
import { useMilestone } from '../../lib/hooks.js'
import { IssueBlock } from './IssueBlock.js';
import { connect, useSelector } from 'react-redux';

const LeftRightText = ({ left, right }) => {
    return <div className="w3-row">
        <div className="w3-left cgs-status-text">{left}</div>
        <div className="w3-right cgs-status-text">{right}</div>
    </div>
}


export default class IssueGroup {
    constructor(uname, name, type = 'member') {
        this.name = name;
        this.type = type;
        this.uname = uname;
        this.tickets = [];

        this.toPlanCount = 0;
        this.totalSpent = 0;
        this.totalEstimate = 0;
        this.totalSelfClosed = 0;
        this.closedEst = 0;
        this.filtered = [];
        this.propgress = 0;
        this.projects = {};

    }

    addIssue(ticket) {
        this.tickets.push(ticket);
        this.totalEstimate += ticket.timeEstimate;
        this.totalSpent += ticket.timeSpent;
        if (ticket.timeEstimate === null) {
            this.toPlanCount++;
        }
        if (ticket.state === 'closed') {
            this.closedEst += ticket.timeEstimate;
        }

        if (ticket.closedBy && ticket.assignee && ticket.closedBy.username === ticket.assignee.username) {
            this.totalSelfClosed++;
        }
        if (ticket.project) {
            this.projects[ticket.project.name] = ticket.timeEstimate;
            this.projects[ticket.project.name] = ticket.timeSpent;
        }
    }

    getStats(milestone) {

        const order = Object.keys(this.projects).map(el => ({
            est: this.projects[el],
            key: el.replace(/-_/g, ' ')
        })).sort((a, b) => b.est - a.est); 
        const stats = {
            fname: this.name.split(' ').shift(),
            name: this.name,
            uname: this.uname,
            count: this.tickets.length,
            tmProgress: 0,
            tmExpired: 0,
            tmTotal: 0,
            projects: order.map(el => el.key),
            vals: {
                completed: 0,
                spent: 0,
                estimate: 0,
                filter: 0,
                closedEst: this.closedEst,
                days: 0,
                selfClosed: this.totalSelfClosed,
                toPlan: 0
            },
            perc: {
                completed: 0,
                spent: 0,
                estimate: 0,
                filter: 0,
                days: 0,
                toPlan: 0
            }
        }
        if (this.tickets.length <= 0 || !milestone) {
            return stats;
        }
        const days = workingDays(
            milestone.start,
            milestone.end
        );
        const daysCompleted = workingDays(
            milestone.start,
            moment().add(-1, 'Days').format("YYYY-MM-DD")
        );
        const tmTotal = days * 8 * 60 * 60;
        const toLog = daysCompleted * 8 * 60 * 60;
        stats.tmTotal = roundUpHour(tmTotal);
        stats.tmProgress = (Math.min(days, daysCompleted) * 100 / days).toPrecision(3);
        stats.tmExpired = stats.tmProgress * 3600 * 8;

        stats.vals.estimate = roundUpHour(this.totalEstimate);
        stats.perc.estimate = (this.totalEstimate * 100 / tmTotal).toFixed(2);

        stats.vals.completed = this.tickets.filter(item => item.state === 'closed').length;
        stats.perc.completed = (stats.vals.completed * 100 / this.tickets.length).toPrecision(3);

        stats.vals.spent = roundUpHour(this.totalSpent);
        stats.perc.spent = (this.totalSpent * 100 / tmTotal).toPrecision(3);
        stats.vals.filter = this.filtered.length;
        stats.perc.filter = (this.filtered.length * 100 / this.tickets.length).toPrecision(3);
        stats.vals.toPlan = this.toPlanCount;
        return stats;
    }

    filter(filter) {
        this.filtered = filterIssues(this.tickets, filter);
        return this.filtered;
    }
}

export const IssueGroupView = ({ group, filter }) => {

    const userInfo = useSelector(st => st.userInfo);
    const milestone = useMilestone();

    const list = group.filtered.map((item) => {
        return <IssueBlock key={item.id}
            loadLinked={true} item={item}
            user={userInfo}
            labelsToRemove={item.labelsToRemove} />
    });



    const stats = group.getStats(milestone);
    let cls = 'w3-green';
    if (group.type !== 'member') {
        cls = 'w3-indigo';
    } else {
        cls = getDiffClass(stats.tmTotal - group.totalEstimate,
            [24 * 3600, 16 * 3600, 8 * 3600]
        );
    }

    if (list.length === 0) {
        return null;
    }

    const topSection = <div className="cgs-progress-text" >
        <LeftRightText
            left={`Estimate : ${formatSecs(stats.vals.estimate)}`}
            right={`${stats.perc.estimate}%`}
        />
        <LeftRightText
            left={`Spent : ${formatSecs(stats.vals.spent)}`}
            right={`${stats.perc.spent}%`}
        />
        <LeftRightText
            left={`Completed : ${stats.vals.completed}/${group.tickets.length}`}
            right={`${stats.perc.completed}%`}
        />
        {
            (filter === 'completed' || filter === 'all') ? null : <LeftRightText
                left={`${filter.toUpperCase()} Issues : ${list.length} / ${group.tickets.length}`}
                right={`${stats.perc.filter}%`}
            />
        }
    </div>

    const progressUI = <div className="cgs-i-progress">
        <div className="prog" style={{
            position: "absolute",
            height: "100%",
            borderRight: "solid 2px #5ae8db",
            background: "rgb(0,0,0,0.2)",
            width: stats.tmProgress + "%"
        }}>
        </div>
        <div className="prog" style={{ background: "#b70198", width: stats.perc.estimate + "%" }}>
        </div>
        <div className="prog" style={{ background: "#005aff", width: stats.perc.spent + "%" }}>
        </div>
        <div className="prog" style={{ background: "#0aef70", width: stats.perc.completed + "%" }}>
        </div>
    </div>
    return <div key={group.uname} className="cgs-col">
        <div className={`w3-padding-small ${cls} `}>
            <div>
                <h6>{group.name}</h6>
              
            </div>
            {progressUI}
            {topSection}
        </div>
        {list}
    </div>
}
